import React from 'react';
import BlogClassicData from '../../data/blog/BlogList.json';
import { slugify} from "../../utils"
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import CategoryListPost from '../../components/blog/CategoryListPost';
import HeaderBlog from '../../common/header/HeaderBlog';
import FooterFour from '../../common/footer/FooterFour';


// const TagList = ({match: {params: {slug}}}) => {
const TagList = ({ params }) => {
    console.log('params: ',params);
    const data = BlogClassicData.map(blog => {
        return {
            ...blog,
            tags: blog.tags.filter(data => slugify(data) === params.tag)
        }
    }).filter(blog => blog.tags.length > 0);
    if (data[0]) {
        const tagTitle = data[0].tags[0];

        return (
            <>
                <SEO title="Blogs" />
                {/* <Layout> */}
                <div>
                    <HeaderBlog btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />
                    <BreadcrumbOne 
                        title={tagTitle}
                        rootUrl="/"
                        parentUrl="Home"
                        currentUrl={tagTitle}
                    />
                    <div className="main-content">
                        {/* Start Blog Area  */}
                        <div className="rn-blog-tag-area rn-section-gap">
                            <div className="container">
                                <CategoryListPost Column="col-lg-4 mt--30" data={data} />
                            </div>
                        </div>
                        {/* End Blog Area  */}
                    </div>
                {/* </Layout> */}
                </div>
                <FooterFour />
            </>
        )
    } else {
        return (
            <> </>
        )
    }
}
export default TagList;